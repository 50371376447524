/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import { Header, Footer, Responsive } from 'client/components/common';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';

import bg from 'assets/client/images/main-bg-desktop.png';
import bgMob from 'assets/client/images/main-bg-mobile.png';
import mainLogoBgLeft from 'assets/client/images/main-logo-bg-left.png';

import ContentForm from 'client/components/common/ContentForm';
import ClosedActivityPopup from 'client/components/common/Popups/ClosedActivityPopup';
import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
|};

type State = {};
class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	state = {};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	render() {
		const { className, transitionState } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		return (
			<div className={classNames(css.intro, css[transitionState], className)}>
				<Header isMobile={Responsive.isMatching(Responsive.MOBILE)} />
				<div className={css.content} style={{ backgroundImage: `url(${isMobile ? bgMob : bg})` }}>
					<div className={css.contentInner}>
						<div className={css.mainLogo}>
							<img className={css.mainLogoBg} src={mainLogoBgLeft} alt="" />
							<div className={css.title}>חושבים שהילדים שלכם אלופים?</div>
						</div>
						<div className={css.mainContent}>
							<p className={css.description}>
								טניס, כדורגל, ריקוד, כדורסל, אתלטיקה קלה - פרויקט "בוקר טוב יותר" המשותף ל-ynet "ידיעות
								אחרונות" ותלמה, מאמין שכל הילדים אלופים, כל אחד ואחת בתחומו/ה.
							</p>
							<p className={classNames(css.description, css.withBigPadding)}>
								לכן, אנחנו מזמינים אתכם ההורים, לספר לנו במה הילדים שלכם עוסקים ולמה הם אלופים, ואולי
								הילדים שלכם יזכו בפרס.
							</p>
							<ContentForm />
						</div>
					</div>
				</div>
				<Footer />
				<ClosedActivityPopup className="show" />
			</div>
		);
	}
}

export default withTransition(Intro);
