/* eslint-disable no-useless-escape */
// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { detectIE } from 'common/utils/detect-ie';
import { ROOT_ROUTE } from 'client/constants';
import Intro from 'client/components/pages/Intro';

import faviconImg from 'assets/favicon.png';
import * as css from './App.scss';

type Props = {|
	history: RouterHistory,
	location: RouterLocation,
	isBusy?: boolean,
|};

class App extends React.PureComponent<Props> {
	static defaultProps = {
		isBusy: true,
	};

	async componentDidMount() {
		const isIE = detectIE();

		// tracker.init('UA-142761482-39');
		// this.props.checkAuthState();

		if (!this.props.isBusy) {
			this.removeRootPreloader();
		}

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');
		}
	}

	async componentDidUpdate(prevProps: Props) {
		if (!this.props.isBusy && prevProps.isBusy) {
			this.removeRootPreloader();
		}
	}

	renderHelmet = () => {
		const title = 'חושבים שהילדים שלכם אלופים? – ynet';
		// eslint-disable-next-line max-len
		const description = `במסגרת פרויקט "בוקר טוב יותר" המשותף ל"ידיעות אחרונות", ynet ותלמה, מאמין שכל הילדים אלופים, כל אחד ואחת בתחומו\ה. לכן, אנחנו מזמינים אתכם ההורים, לספר לנו במה הילדים שלכם עוסקים ולמה הם אלופים, ואולי הילדים שלכם יזכו בפרס.`;
		// Fix for whatsapp share on IOS
		//! Attention: do not import og images as variable, write path here:
		const image = 'https://ynet-yeladim.firebaseapp.com/assets/client/share-min.png?v=1';

		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: image },
					{ property: 'og:url,', content: 'https://yeladimalufim.ynet.co.il/' },
					{ property: 'og:type', content: 'website' },
				]}
			/>
		);
	};

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 2500);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	renderRoute = (Component: Function) => {
		return ({ match, ...props }) => {
			return <Component {...props} in={match !== null} />;
		};
	};

	render() {
		return (
			<div id="app" className={css.app}>
				{this.renderHelmet()}
				<div className={css.content}>
					<Route exact path={ROOT_ROUTE}>
						{props => <Intro {...props} in={!!props.match} />}
					</Route>
				</div>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	isBusy: false,
});

const mapDispatch = {};

export default withRouter(connect(mapState, mapDispatch)(App));
