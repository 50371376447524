/* eslint-disable react/no-unescaped-entities */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import * as css from './SendedForm.scss';

type Props = { className?: string, closePopup: Function };

const SendedFormPopup = ({ className, closePopup }: Props) => {
	const clickOnCloseListener = () => {
		closePopup();
	};
	return (
		<div className={classNames(css.sendedForm, className)}>
			<div className={classNames(css.sendedFormWrapper)}>
				<div className={css.closeBtn} onClick={clickOnCloseListener}></div>
				<span className={css.title}>תודה, פרטיך נקלטו במערכת</span>
			</div>
		</div>
	);
};

SendedFormPopup.defaultProps = { className: '' };

export default SendedFormPopup;
