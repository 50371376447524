import { CAN_USE_DOM } from '../constants';

export const dataLayerEvents = {
	/**
	 * @param label {String}
	 */
	onShareBtnClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'share_events',
			Category: 'Social',
			Action: 'Share From Header',
			Label: label,
		}),
	onFormSended: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'form_events',
			Category: 'Form',
			Action: 'Form sent',
			Label: label,
		}),
};
