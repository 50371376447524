/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
// @flow

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	LEADS: 'leads',
};

export const PLACEHOLDERS_TEXTS = {
	fullName: 'שם ההורה',
	phone: 'טלפון',
	email: 'כתובת מייל',
	childAge: 'גיל',
	childFullName: 'שם ילד/ה',
	childOccupation: 'תחום עיסוק של הילד/ה',
	message: 'ספרו לנו על הילדים שלכם, במה הם עוסקים ולמה אתם חושבים שהם אלופים (עד 1000 תווים):',
	address: 'עיר מגורים',
};

export const ERRORS_TEXTS = {
	fullName: 'יש למלא שם הורה',
	email: 'יש למלא כתובת מייל',
	emailNotValid: 'כתובת מייל לא תקינה',
	phoneNumber: 'יש למלא מספר טלפון',
	phoneNumberNotValid: 'מספר טלפון לא תקין',
	address: 'יש למלא עיר מגורים',
	childFullName: `יש למלא שם ילד\ה`,
	childAge: 'יש למלא גיל',
	childOccupation: 'ספרו לנו על הילדים שלכם',
	message: 'ספרו לנו על הילדים שלכם',
	messageMaxLength: 'ספרו לנו על הילדים שלכם. עד 1000 תווים',
	checkbox: 'יש לאשר את התקנון,תנאי השימוש,ומדיניות הפרטיות',
};

export const texts = {
	introTitle: 'רוצים שכולם יכירו את ענף הספורט שלכם?',
};
