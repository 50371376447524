/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';

import { LEAD_CONSTANTS, CAN_USE_DOM } from 'common/constants';
import Icon from 'common/components/Icon';

import { PLACEHOLDERS_TEXTS, COLLECTIONS, ERRORS_TEXTS } from 'client/constants';
import { Form, TextField, Checkbox, Button } from 'client/components/common';
import { initialize, SubmissionError, reset } from 'redux-form';
import { createItem } from 'client/actions';
import { dataLayerEvents } from 'common/utils/gtag';
import termsPdfFile from 'assets/client/terms.pdf';
import SendedFormPopup from '../Popups/SendedFormPopup';
import AlreadyInSystemPopup from '../Popups/AlreadyInSystemPopup';
import * as css from './ContentForm.scss';

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;
// eslint-disable-next-line
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type FormData = {
	fullName?: string,
	message?: string,
	checkbox?: boolean,
	email?: string,
	phoneNumber?: string,
	address?: string,
	childFullName?: string,
	childAge?: string,
	childOccupation?: string,
};

type Props = {
	values: FormData,
	errors: FormData,
	createItem: typeof createItem,
	resetForm: Function,
};

type State = {
	isUserNotified: boolean,
	isShowSenededFormPopup: boolean,
	isShowAlreadyInSystemPopup: boolean,
	isLoading: boolean,
};

const FORM_CONFIG = {
	form: 'userInfo',
};

class ContentForm extends React.PureComponent<Props, State> {
	static defaultProps = {
		// className: '',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isUserNotified: false,
			isShowSenededFormPopup: false,
			isShowAlreadyInSystemPopup: false,
			isLoading: false,
		};
	}

	initFormValues = async () => {};

	onFormSubmit = async (values: FormData) => {
		const { resetForm } = this.props;
		this.setState({
			isUserNotified: false,
			isLoading: true,
		});

		const errorsObj = {};

		if (!values.fullName) {
			errorsObj.fullName = ERRORS_TEXTS.fullName;
		}

		if (values.fullName) {
			if (values.fullName && values.fullName.trim().length === 0) {
				errorsObj.fullName = ERRORS_TEXTS.fullName;
			}
		}

		if (!values.email) {
			errorsObj.email = ERRORS_TEXTS.email;
		} else if (values.email) {
			const validEmail = emailRegExp.test(values.email.toLocaleLowerCase());
			if (!validEmail) {
				errorsObj.email = ERRORS_TEXTS.emailNotValid;
			}
		}

		if (!values.phoneNumber) {
			errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumber;
		} else if (values.phoneNumber) {
			const validPhone = phoneRegExp.test(values.phoneNumber.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumberNotValid;
			}
		}

		if (!values.address) {
			errorsObj.address = ERRORS_TEXTS.address;
		}

		if (values.address) {
			if (values.address && values.address.trim().length === 0) {
				errorsObj.address = ERRORS_TEXTS.address;
			}
		}

		if (!values.childFullName) {
			errorsObj.childFullName = ERRORS_TEXTS.childFullName;
		}

		if (values.childFullName) {
			if (values.childFullName && values.childFullName.trim().length === 0) {
				errorsObj.childFullName = ERRORS_TEXTS.childFullName;
			}
		}

		if (!values.childAge) {
			errorsObj.childAge = ERRORS_TEXTS.childAge;
		}

		if (!values.childOccupation) {
			errorsObj.childOccupation = ERRORS_TEXTS.childOccupation;
		}

		if (!values.message) {
			errorsObj.message = ERRORS_TEXTS.message;
		} else if (values.message && values.message.length > 1000) {
			errorsObj.message = ERRORS_TEXTS.messageMaxLength;
		}

		if (!values.checkbox) {
			errorsObj.checkbox = ERRORS_TEXTS.checkbox;
		}

		if (!_.isEmpty(errorsObj)) {
			const errosWithoutCheckboxes = _.omit(errorsObj, 'checkbox');
			if (errorsObj.checkbox) {
				this.setState({
					isLoading: false,
				});
			} else if (_.size(errosWithoutCheckboxes) > 0) {
				this.setState({
					isLoading: false,
				});
			}

			throw new SubmissionError(errorsObj);
		}

		if (_.isEmpty(errorsObj)) {
			const leadResult = await this.props.createItem<any>({ collection: COLLECTIONS.LEADS, data: values });

			if (leadResult.lead === LEAD_CONSTANTS.FOUNDED) {
				resetForm('userInfo');
				this.setState({
					isShowAlreadyInSystemPopup: true,
					isLoading: false,
				});
				dataLayerEvents.onFormSended(window.location.href);
			}

			if (leadResult.lead === LEAD_CONSTANTS.CREATED) {
				resetForm('userInfo');
				this.setState({
					isShowSenededFormPopup: true,
					isLoading: false,
				});
				dataLayerEvents.onFormSended(window.location.href);
			}
		}
	};

	closeErrorPopup = () => {
		this.setState({
			isUserNotified: true,
		});
	};

	closeAlreadyInSystemPopup = () => {
		this.setState({
			isShowAlreadyInSystemPopup: false,
		});
	};

	closeSendedFormPopup = () => {
		this.setState({
			isShowSenededFormPopup: false,
		});
	};

	render() {
		// const { errors } = this.props;
		// const { isUserNotified, isShowSenededFormPopup, isShowAlreadyInSystemPopup } = this.state;
		const { isShowSenededFormPopup, isShowAlreadyInSystemPopup } = this.state;

		let macOs;

		if (CAN_USE_DOM) {
			macOs = /(Mac)/i.test(navigator.platform);
		}

		return (
			<div className={css.formWrapper}>
				<Form className={css.form} config={FORM_CONFIG} onSubmit={this.onFormSubmit}>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="fullName"
									placeholder={PLACEHOLDERS_TEXTS.fullName}
									pattern="^[\u0590-\u05fe ]+$"
								/>
							</div>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="email"
									placeholder={PLACEHOLDERS_TEXTS.email}
									pattern="^[a-z\.\-\@\0-9]"
								/>
							</div>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="phoneNumber"
									placeholder={PLACEHOLDERS_TEXTS.phone}
									pattern="[+\0-9\-]"
									isLtr
								/>
							</div>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="address"
									placeholder={PLACEHOLDERS_TEXTS.address}
									pattern="^[\u0590-\u05fe ]+$"
								/>
							</div>
							<div className={classNames(css.fieldWrapper)}>
								<div className={css.innerFieldsWrapper}>
									<div className={classNames(css.innerFieldWrapper, css.longField)}>
										<TextField
											name="childFullName"
											placeholder={PLACEHOLDERS_TEXTS.childFullName}
											pattern="^[\u0590-\u05fe ]+$"
										/>
									</div>
									<div className={classNames(css.innerFieldWrapper)}>
										<TextField
											name="childAge"
											placeholder={PLACEHOLDERS_TEXTS.childAge}
											minNumber={1}
											maxNumber={120}
											pattern="[0-9]"
											maxLength={2}
										/>
									</div>
								</div>
							</div>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="childOccupation"
									placeholder={PLACEHOLDERS_TEXTS.childOccupation}
									pattern="^[\u0590-\u05fe ]+$"
								/>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.withTextArea)}>
						<div className={css.fieldsWrapper}>
							<div className={classNames(css.fieldWrapper, css.fullWidthField)}>
								<TextField
									name="message"
									placeholder={PLACEHOLDERS_TEXTS.message}
									isTextarea
									textareaType={2}
									pattern="^[\u0590-\u05FF 0-9 /\W\r\n-]+$"
								/>
								{this.props.values.message && this.props.values.message.length > 0 && (
									<span className={css.wordCounter}>
										1000 / {this.props.values.message ? this.props.values.message.length : 0}
									</span>
								)}
								<span className={css.textAreaAdditionalText}>עד 1000 תווים</span>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.withButton)}>
						<div className={classNames(css.checkboxWrapper)}>
							<Checkbox
								name="checkbox"
								label={
									<>
										מסכים/ה{' '}
										<a href={termsPdfFile} target="_blank" rel="noreferrer">
											לתקנון
										</a>
										,{' '}
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
											target="_blank"
											rel="noreferrer"
										>
											תנאי השימוש
										</a>{' '}
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html"
											target="_blank"
											rel="noreferrer"
										>
											ומדיניות הפרטיות
										</a>{' '}
										ולקבלת פניות שיווקיות כולל טלפוניות מ-ynet הנ"ל איננה הסכמה לעריכת עסקה.
									</>
								}
							/>
						</div>
						<div className={css.btnWrapper}>
							{this.state.isLoading && <Icon type="preloader" className={css.preloader} />}
							<Button
								className={classNames(css.submitBtn, !macOs && css.forWindows)}
								label={this.state.isLoading ? '' : 'שלח'}
								type="submit"
							/>
						</div>
					</div>
				</Form>
				<AlreadyInSystemPopup
					className={isShowAlreadyInSystemPopup ? 'show' : ''}
					closePopup={this.closeAlreadyInSystemPopup}
				/>
				<SendedFormPopup
					className={isShowSenededFormPopup ? 'show' : ''}
					closePopup={this.closeSendedFormPopup}
				/>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => {
	const values = 'form.userInfo.values';
	const errors = 'form.userInfo.submitErrors';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
	};
};

const mapDispatch = {
	initializeForm: initialize,
	createItem,
	resetForm: reset,
};

export default connect(mapState, mapDispatch)(ContentForm);
