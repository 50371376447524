// @flow
import * as React from 'react';
import classNames from 'classnames';
import * as css from './AlreadyInSystem.scss';

type Props = { className?: string, closePopup: Function };

const AlreadyInSystemPopup = ({ className, closePopup }: Props) => {
	const clickOnCloseListener = () => {
		closePopup();
	};
	return (
		<div className={classNames(css.alreadyInSystem, className)}>
			<div className={classNames(css.alreadyInSystemWrapper)}>
				<div className={css.closeBtn} onClick={clickOnCloseListener}></div>
				<p>
					<span>פרטיך כבר במערכת</span>
				</p>
			</div>
		</div>
	);
};

AlreadyInSystemPopup.defaultProps = { className: '' };

export default AlreadyInSystemPopup;
